import React, { useState, useEffect } from 'react';

import axios from 'axios';
import globalConfig from '../../config/config';

const Share = () => {
  const API_ENDPOINT_JOBS = `${globalConfig.api}/renderjobs`
  const S3_PATH_PREFIX = `https://cdn1.netizens.ai`

  const [job, setJob] = useState(null);
  const [results, setResults] = useState([]);
  const [video, setVideo] = useState(null);

  // get the job id from the url, which will be a query param
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const jobId = urlParams.get('video');
    if (jobId) {
      loadJob(jobId);
    }
  }, []);

  // load a job
  const loadJob = (id) => {
    axios.get(API_ENDPOINT_JOBS + `/${id}`, {
      headers: {
       
      }})
      .then(response => {
        const resJob = response.data
        setJob(resJob);

        console.log('loaded job', resJob);
      })
      .catch(error => {
        console.error(error);
      });
  };

  // if a job was loaded, find the output
  useEffect(() => {
    if (job) {
      const jobResults = job?.config?.output?.result
      setResults(jobResults);

      console.log('job results', jobResults);
    }
  }, [job]);

  // try finding the video in the results
  useEffect(() => {
    if (results) {
      for (let i = 0; i < results.length; i++) {
        const resVideo = results[i]?.media?.s3

        if (resVideo) {
          setVideo(S3_PATH_PREFIX + '/' + resVideo);

          console.log('found video', resVideo);
          break;
        }
      }
    }
  }, [results]);

  return (
    <div className="h-full min-h-screen">
      <video
        controls
        src={video}
        type="video/mp4"
        style={{ height: "100vh", width: "100%", objectFit: "contain" }}
      >
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  
  )
}

export default Share;