import React, { useState, useEffect } from 'react';

import config from '../../config/config';
import axios from 'axios';
import { getAuth, authHeader } from '../../utils/auth';

const VideoCard = ({ data }) => {

    return (
        <div>
            <h2 className="text-lg font-bold mb-2">{data.title}</h2>
            <video className="w-full h-auto rounded-lg shadow-lg" controls>
                <source src={`${data.url}`} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="text-sm text-gray-500 mt-2">
                <a href={data.sharing_url} target="_blank" rel="noopener noreferrer">
                    {data.sharing_url}
                </a>
            </div>
        </div>
    )
}

const AdminVideos = () => {
    const SITE_URL = 'https://netizens.ai'
    //const SITE_URL = 'http://localhost:3001'
    const S3_PATH_PREFIX = `https://cdn1.netizens.ai`
    const API_ENDPOINT_JOBS = `${config.api}/renderjobs`

    const NUM_VIDEOS = 20; // how many videos to load at a time
    const [videos, setVideos] = useState([]);
    const [nextPage, setNextPage] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadMoreVideos();
    }, []);

    const loadMoreVideos = () => {
        if(nextPage === -1) {
            console.log('no more pages');
            return;
        }

        setLoading(true);

        console.log(`loading page ${nextPage}`);
        return axios.get(API_ENDPOINT_JOBS+`?admin=true&limit=${NUM_VIDEOS}&page=${nextPage+1}&sortBy=_id:desc`, {
            headers: {
                ...authHeader()
            }})
            .then(response => {
                console.log('loaded user jobs', response.data.results);
                const data = response.data.results.map(m => {
                    return {
                        url: `${S3_PATH_PREFIX}/${m.config.output.result[0].media.s3}`,
                        title: m.config.output.result[0].title,
                        sharing_url: `${SITE_URL}/share?video=${m.id}`,
                    }
                })

                setVideos([...videos, ...data]);

                console.log(`total pages: ${response.data.totalPages}`);

                if(nextPage + 1 === response.data.totalPages) {
                    setNextPage(-1);
                } else {
                    setNextPage(nextPage+1);
                }
            })
            .finally(() => {
                setLoading(false);
            })
            .catch(e => {
                console.log(e);
            });
    }

    // lazyload videos as the user scrolls
    const handleScroll = () => {
        console.log('scrolling')
        if(window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight) {
            if(!loading) {
                loadMoreVideos();
            }
        }
    }

    return (
        <div className="grid grid-cols-4 gap-4 h-dvh overflow-y-scroll" onScroll={handleScroll}>
            {videos.map((v, i) => <VideoCard key={i} data={v} />)}
        </div>
    )
}

export default AdminVideos;