import React, { useState, useRef, useEffect } from 'react';

import config from '../../config/config';
import axios from 'axios';
import globalConfig from '../../config/config';
import { getAuth, authHeader } from '../../utils/auth';

import Fields from '../common/Fields';
import NewView from "../common/NewView";
import VoiceCardMini from '../Voices/VoiceCardMini';
import ModelCardMini from '../Models/ModelCardMini';

const OneOff = ({backEvent, setBackEvent, setBackEnabled}) => {
  const API_ENDPOINT_PIPELINES = `${globalConfig.api}/pipelines`
  const API_ENDPOINT_NETIZENS = `${globalConfig.api}/netizens`
  const API_ENDPOINT_VOICES = `${globalConfig.api}/voices`
  const API_ENDPOINT_MODELS = `${globalConfig.api}/models`
  const S3_PATH_PREFIX = `https://cdn1.netizens.ai`

  const [voices, setVoices] = useState([]);
  const [models, setModels] = useState([]);

  const [message, setMessage] = useState('');
  const [createEnabled, setCreateEnabled] = useState(true);

  const word_limit = 200;
  const [scriptSublabel, setScriptSublabel] = useState(`Word limit: ${word_limit}`)

  const video_examples = []
  const styles = [
    { value: 1, label: 'Selfie', description: 'A style where the netizen is holding the camera as if it were recording with a front facing phone', credits: 10 },
    { value: 2, label: 'Upper', description: 'A style where only the netizen\'s upper body is shown', credits: 20 },
    // { value: 3, label: 'Floating', description: 'A style where the camera is further away from the netizen, always looking towards them' },
  ]

  // form data
  const [script, setScript] = useState("Welcome fellow degenerates! I am a netizen created by the netizens.ai platform. What do you want me to post about?");
  const [model, setModel] = useState(null);
  const [voice, setVoice] = useState(null);
  const [style, setStyle] = useState(1);
  const [numCredits, setNumCredits] = useState(styles[0].credits);

  useEffect(() => {
    loadVoices();
    loadModels();
  }, []);

  // after createEnabled is set to false, set it back to true after 5 seconds
  useEffect(() => {
    if (!createEnabled) {
      const timer = setTimeout(() => {
        setCreateEnabled(true);
        setMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [createEnabled]);

  // load available voices for the current user
  const loadVoices = () => {
    const {user} = getAuth();
    const user_id = user.id;
    
    return axios.get(API_ENDPOINT_VOICES, {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        const resVoices = response.data.results.map(m => { 
          return {
            id: m.id,
            name: m.name,
            description: m.description,
            active: m.active,
            file: m.file ? `${S3_PATH_PREFIX}/${m.file}` : null,
            sample: m.sample ? `${S3_PATH_PREFIX}/${m.sample}` : null,
            owner: m.owner === user_id
          }
        })
        
        setVoices(resVoices);
        setVoice(resVoices[0].id);
      })
      .catch(error => {
        console.error(error);
      });
  };

  // load available models for the current user
  const loadModels = () => {
    const {user} = getAuth();
    const user_id = user.id;
    
    return axios.get(API_ENDPOINT_MODELS, {
      headers: {
        ...authHeader()
      }})
      .then(response => {
        const resModels = response.data.results.map(m => { 
          return {
            id: m.id,
            name: m.name,
            description: m.description,
            picture: m.picture,
            public: m.public,
            file: m.file,
            owner: m.owner === user_id
          }
        })
        
        setModels(resModels);
        setModel(resModels[0].id);
      })
      .catch(error => {
        console.error(error);
      });
  };

  // handle creation
  const onClickCreate = async () => {
    try {
      // validate
      if (!script || !voice || !model || !style) {
        setMessage('Please fill in all fields');
        throw new Error('Please fill in all fields');
      }

      let res =  null;

      // create a netizen based on the info, if it doesn't already exist
      const netizenName = "test netizen"
      const netizenDescription = "automatically generated test netizen"
      const netizenPersona = "funny and cute"

      // try to find a netizen with this name
      res = await axios.get(API_ENDPOINT_NETIZENS, {
          headers: {
            ...authHeader()
          },
          params: {
            name: netizenName
          }
        })
        .catch(error => {
          console.error(error);
          setMessage(error.response.data.message)
          throw error;
        })

      let newNetizen = null
      if (res.data.results.length > 0) {
        // if it exists, use it
        console.log('Netizen already exists, so update it')
        newNetizen = res.data.results[0]
      }

      // post to endpoint
      if (!newNetizen) {
        // Create form data
        const netizenForm = new FormData();
        netizenForm.append('name', netizenName);
        netizenForm.append('description', netizenDescription);
        netizenForm.append('persona', netizenPersona);
        netizenForm.append('model', model);
        netizenForm.append('voice', voice);

        res = await axios.post(API_ENDPOINT_NETIZENS, netizenForm, {
            headers: {
              ...authHeader(),
              'Content-Type': 'multipart/form-data',
            },
          })
          .catch(error => {
            console.error(error);
            setMessage(error.response.data.message)
            throw error;
          })

        newNetizen = res.data
      } else {
        const netizenBody = {
          name: netizenName,
          description: netizenDescription,
          persona: netizenPersona,
          model: model,
          voice: voice,
        }
        res = await axios.patch(`${API_ENDPOINT_NETIZENS}/${newNetizen.id}`, netizenBody, {
            headers: {
              ...authHeader(),
            },
          })
          .catch(error => {
            console.error(error);
            setMessage(error.response.data.message)
            throw error;
          })

        newNetizen = res.data
      }

      console.log(newNetizen)

      // if successful, create a pipeline, if it doesn't already exist
      const pipelineName = "test pipeline"
      const pipelineDescription = "automatically generated test pipeline"
      const pipelineType = "ScriptPipeline"
      const pipelineCredits = numCredits
      const pipelineConfig = {
        credits: pipelineCredits,
        num_experiences: 1,
        generate_selfie: true,
        generate_subtitles: false,
        script: script,
      }

      // try to find a pipeline with this name
      res = await axios.get(API_ENDPOINT_PIPELINES, {
          headers: {
            ...authHeader()
          },
          params: {
            name: pipelineName
          }
        })
        .catch(error => {
          console.error(error);
          setMessage(error.response.data.message)
          throw error;
        })
        
      let newPipeline = null
      if (res.data.results.length > 0) {
        // if it exists, use it
        console.log('Pipeline already exists, so update it')
        newPipeline = res.data.results[0]
      }

      // post to endpoint
      let pipelineBody = {
        name: pipelineName,
        description: pipelineDescription,
        public: false,
        type: pipelineType,
        config: pipelineConfig
      }

      if (!newPipeline) {
        res = await axios.post(API_ENDPOINT_PIPELINES, pipelineBody, {
            headers: {
              ...authHeader()
            }
          })
          .catch(error => {
            console.error(error);
            setMessage(error.response.data.message)
            throw error;
          })

        newPipeline = res.data
      } else {
        res = await axios.patch(`${API_ENDPOINT_PIPELINES}/${newPipeline.id}`, pipelineBody, {
            headers: {
              ...authHeader()
            }
          })
          .catch(error => {
            console.error(error);
            setMessage(error.response.data.message)
            throw error;
          })
        newPipeline = res.data
      }

      console.log(newPipeline)

      // if successful, create a one off job
      let jobBody = {
        config: {
          ...newPipeline.config,
          netizens: [
            newNetizen.id
          ],
        },
      }

      res = await axios.post(`${API_ENDPOINT_PIPELINES}/${newPipeline.id}/test`,
        jobBody, 
        {
          headers: {
            ...authHeader()
          }
        })
        .catch(error => {
          console.error(error);
          setMessage(error.response.data.message)
          throw error;
        })

      let newJob = res.data
      console.log(newJob)

      // set message
      setCreateEnabled(false);
      setMessage('Video scheduled successfully! You will be emailed when the video is generated, so make sure it doesn\'t get sent to spam.')
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <NewView>
      <div className="flex justify-between items-center mb-4 space-x-20">
        <div>
          <div className="flex items-center space-x-3">
            <h1 className="text-lg font-regular m-0 mr-2">
              Generate
            </h1>
          </div>
        </div>
      </div>
      
      <div className="h-full overflow-scroll">
        <div className="flex flex-row justify-between pr-10 mb-4 overflow-scroll">
          {video_examples.filter(e=>e.type==="youtube").slice(0,3).map((example, index) => {
            return (
              <div key={index} className="">
                <label className="text-sm font-semibold text-gray-500">Example {index+1}</label>
                <iframe
                  title={`example-video-${index}`}
                  frameborder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                  src={example.url}
                />
              </div>
            )})
          }
        </div>

        <div className="flex flex-col space-y-0">
          <Fields.DescriptionField 
            label="Script" 
            placeholder="Script" 
            sublabel={scriptSublabel}
            value={script} 
            onChange={(e) => {
              const words = e.target.value.split(' ').filter(word => word.length > 0);
              if (words.length > word_limit) {
                setScriptSublabel(`Word limit reached: ${word_limit}`);
                setScript(script.trim())
              } else {
                setScriptSublabel(`Word limit: ${word_limit - words.length}`);
                setScript(e.target.value)
              }
            }}
          />

          <Fields.SingleSelect
            id="voice"
            label="Select Voice" 
            sublabel=""
            render={(value) => {
              const m = voices?.find(voice => voice.id === value);
              if (!m) return null;
              return (
                <VoiceCardMini key={0} voice={m} onClick={()=>{}} />
              )
            }
            }
            info="(select one)" 
            value={voice}
            onChange={(value) => {
              setVoice(value)
            }} 
            options={
              [...voices]?.map(voice => ({
                value: voice.id,
                label: voice.name
              }))
            }
          />

          <Fields.SingleSelect
            id="model"
            label="Select Model" 
            sublabel=""
            render={(value) => {
              const m = models?.find(model => model.id === value);
              if (!m) return null;
              return (
                <ModelCardMini model={m} onClick={()=>{}} />
              )
            }
            }
            info="(select one)" 
            value={model} 
            onChange={(value) => {
              setModel(value)
            }} 
            options={
              [...models]?.map(model => ({
                value: model.id,
                label: model.name
              }))
            }
          />

          <div className="flex flex-col sm:flex-row justify-between sm:space-x-10 pr-10 mb-4">
            <div className="w-full flex-1">
              <Fields.EnumField3
                label="Style"
                sublabel=""
                info={"(select one)"} 
                disabled={false}
                value={style} 
                setValue={
                  (value) => {
                    setStyle(value)
                    setNumCredits(styles.find(s => s.value === value).credits)
                  }} 
                values={styles} 
                margin={false}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col space-x-4 mb-4">
          <Fields.Button type='wide-styled' label={numCredits > 0 ? `Create (${numCredits} credits)` : 'Create'} onClick={()=>onClickCreate()} id="create" disabled={!createEnabled} disabledLabel='Processing' />
          <div className="text-dark-red text-sm">
            {message}
          </div>
        </div>
      </div>
    </NewView>
  )
}

export default OneOff;